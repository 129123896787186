<template>
    <div>
        <div class="level">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title is-4">
                        {{ $t("Interface.IronLogic.List.Title") }}
                    </h1>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <button
                        type="submit"
                        class="button is-theme"
                        @click.prevent="$router.push({ name: 'IronLogic-Add' })"
                    >
                        {{ $t("Interface.IronLogic.List.AddButton") }}
                    </button>
                </div>
            </div>
        </div>
        <b-table
            :data="IronLogicControllers"
            :striped="true"
            :hoverable="true"
            :loading="IsLoading"
            icon-pack="fa"
        >
            <b-table-column v-slot="props" field="controllerId" :label="$t('Interface.Door.Id')" sortable>
                {{ props.row.controllerId }}
            </b-table-column>
            <b-table-column v-slot="props" field="onlineStatus" :label="$t('Interface.IronLogic.OnlineStatus')" sortable>
                <font-awesome-icon
                    :icon="['fas', 'power-off']"
                    size="sm"
                    :class="{ 'has-text-danger': props.row.onlineStatus === 0, 'has-text-success': props.row.onlineStatus === 1 }"
                />
                {{ $t(`Interface.IronLogic.Status.${props.row.onlineStatus}`) }}
            </b-table-column>
            <b-table-column v-slot="props" field="name" :label="$t('Interface.IronLogic.Name')" sortable>
                {{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props" field="address" :label="$t('Interface.Door.Address')" sortable>
                {{ props.row.address }}
            </b-table-column>
            <b-table-column v-slot="props" :label="$t('Interface.DoorProvider.List.Actions')">
                <router-link
                    class="has-text-info"
                    :to="{ name: 'IronLogic-Edit', params: { id: props.row.controllerId } }"
                >
                    <b-tooltip :label="$t('Interface.Button.Edit')" type="is-info" animated>
                        <font-awesome-icon :icon="['fas', 'pen-square']" size="lg" class="is-info" />
                    </b-tooltip>
                </router-link>
                <router-link
                    class="has-text-danger"
                    style="margin-left:0.5rem;"
                    :to="{ name: 'IronLogic-Delete', params: { id: props.row.controllerId } }"
                >
                    <b-tooltip :label="$t('Interface.Button.Delete')" type="is-danger" animated>
                        <font-awesome-icon :icon="['fas', 'times-circle']" size="lg" class="is-danger" />
                    </b-tooltip>
                </router-link>
            </b-table-column>
            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>{{ $t("Interface.Door.List.Empty") }}</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
import LoadingState from "@/mixins/LoadingState"
import { IRONLOGIC_CONTROLLER_LIST_REQUEST } from "@/store/actions/ironlogic"

export default {
    name: "IronLogicControllersList",
    mixins: [LoadingState],
    computed: {
        IronLogicControllersAmount() {
            return this.$store.getters.DOORS_AMOUNT
        },
        IronLogicControllers() {
            return this.$store.state.IronLogic.List
        }
    },
    mounted() {
        if (!this.$store.getters.IS_IRONLOGIC_CONTROLLERS_LOADED) {
            this.switchLoading()
            this.$store.dispatch(IRONLOGIC_CONTROLLER_LIST_REQUEST)
                .finally(() => this.switchLoading())
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.IronLogic.List.Title")
        }
    }
}
</script>
